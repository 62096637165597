import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import styles from './header.module.scss'
import Menu from './menu'

const Header = ({siteTitle}) => (
  <>
    <Helmet
      link={[
        {
          type: 'text/css',
          href: '//cdn.bootcss.com/normalize/8.0.1/normalize.min.css',
          rel: 'stylesheet',
        },
        {
          type: 'text/css',
          href: '//at.alicdn.com/t/font_1302489_a1o95mvoehg.css',
          rel: 'stylesheet',
        },
      ]}
      script={[
        // {
        //   'type': 'text/javascript',
        //   'src': 'http://g.tbcdn.cn/mtb/lib-flexible/0.3.4/??flexible_css.js,flexible.js',
        // },
        {
          type: 'text/javascript',
          innerHTML: 'function setRem(){let htmlWidth=document.documentElement.clientWidth||document.body.clientWidth;let htmlDom=document.getElementsByTagName(\'html\')[0];htmlDom.style.fontSize=htmlWidth/20+\'px\'}if(typeof window!==\'undefined\'){setRem();window.onresize=function(){setRem()}}',
        },
        {
          type: 'text/javascript',
          innerHTML: 'var _hmt=_hmt||[];(function(){var hm=document.createElement("script");hm.src="https://hm.baidu.com/hm.js?e4ef7f039d2526aef35c3a3d3bdf5a3f";var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(hm,s);})();',
        },
        {
          'type': 'text/javascript',
          'src': 'https://www.googletagmanager.com/gtag/js?id=UA-145069110-1',
          'async': 'true',
        },
        {
          type: 'text/javascript',
          innerHTML: 'window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments)}gtag(\'js\',new Date());gtag(\'config\',\'UA-145069110-1\');',
        },
      ]}
    />
    <header className={`${styles.header}`}>
      <div className={`container`}>
        <h1 className={styles.title}>
          <Link to="/" title={siteTitle} rel='index'>
            <img
              style={{verticalAlign: 'middle'}}
              src={'/images/logo.png'}
              alt={siteTitle} />
          </Link>
        </h1>
        <Menu />
      </div>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
