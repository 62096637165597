import PropTypes from 'prop-types'
import React from 'react'

import styles from './index.module.scss'

class Index extends React.Component {
  constructor (props) {
    super(props)
    const {defaultActiveKey, margin} = props
    this.state = {activeKey: defaultActiveKey, navMargin: margin}
  }

  render () {
    let {actionIndex, navMargin} = this.state
    !actionIndex && (actionIndex = this.props.defaultActiveKey)
    let marginLeft = (actionIndex - 1) * -100
    let contents = []
    for (let tab of this.props.children) {
      contents.push(
        React.cloneElement(tab, {
          isActive: tab.key === actionIndex,
        }),
      )
    }

    return <div className={styles.tabs}>
      <div className={styles.nav} style={{marginBottom: navMargin}}>
        {contents.map(content => <div
          key={`${content.props.tab + content.props.key}`}
          className={`${content.props.isActive ? styles.active : ''}`}
          onClick={() => {this.setState({actionIndex: content.key})}}
        >
          {content.props.tab}{content.props.key}
        </div>)}
      </div>
      <div className={styles.content} style={{marginLeft: `${marginLeft}%`}}>
        {contents}
      </div>
    </div>
  }
}

Index.propTypes = {
  defaultActiveKey: PropTypes.string,
  margin: PropTypes.string,
}

Index.defaultProps = {
  defaultActiveKey: `1`,
  margin: '0',
}

export default Index
