import PropTypes from 'prop-types'
import React from 'react'
import Icon from './icon'

import styles from './step.module.scss'

const Step = ({list}) => {
  return <div className={`container-fluid ${styles.bg}`}>
    <div className={`container ${styles.list}`}>
      {list.map(item => <div key={item.name}>
        <Icon className={styles.icon} name={item.icon} />
        <div>{item.name}</div>
      </div>)}
    </div>
  </div>
}

Step.propTypes = {
  list: PropTypes.array.isRequired,
}

Step.defaultProps = {
  list: [
    {icon: 'icongoutong', name: '客户需求'},
    {icon: 'iconfanganguihua', name: '技术方案'},
    {icon: 'iconsheji', name: '设计实施'},
    {icon: 'icontiaoshimoshi', name: '工程调试'},
    {icon: 'iconjiaofuriqi', name: '客户交付'},
  ],
}

export default Step
