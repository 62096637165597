import PropTypes from 'prop-types'
import React from 'react'

import styles from './index.module.scss'

const TabPane = ({children, isActive}) => {
  return <div
    className={`${styles.tabpane} ${!isActive ? styles.inactive : ''}`}>
    {children}
  </div>
}

TabPane.propTypes = {
  isActive: PropTypes.bool,
}

TabPane.defaultProps = {
  isActive: false,
}

export default TabPane
