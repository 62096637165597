import PropTypes from 'prop-types'
import React from 'react'

const Icon = ({name, size, color, className}) => {
  const style = {}
  size && (style.fontSize = size)
  color && (style.color = color)
  return <i className={`iconfont ${name} ${className}`} style={style} />
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  className: PropTypes.string
}

Icon.defaultProps = {
  name: ``,
  size: ``,
  color: ``,
  className: ``,
}

export default Icon
