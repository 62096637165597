import React from 'react'
import styles from './footer.module.scss'
import { Link } from 'gatsby'

const Footer = ({siteTitle}) => {
  const year = new Date().getFullYear()
  return <footer className={'theme-bg'}>
    <div className={`container ${styles.footer}`}>
      <div>
        <Link to="/" title={siteTitle} rel='index'>
          {siteTitle}
        </Link>
      </div>
      <div className={styles.copyright}>
        <small>
          {`Copyright 2017 - ${year} 版权所有`}<br />
          <a
            href="http://www.miitbeian.gov.cn/" target="_blank"
            rel="noopener noreferrer"
          >粤ICP备17145012号-1</a>
        </small>
      </div>
    </div>
  </footer>
}

export default Footer
