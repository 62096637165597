import React from 'react'
// import PropTypes from 'prop-types'

import styles from './menu.module.scss'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import { getMenuList } from '../api/common'

class Menu extends React.Component {

  render () {
    const list = getMenuList()

    return (
      <Location>
        {({location}) => {
          const current = location.pathname + location.hash
          return (
            <div className={styles.menu}>
              {list.map(item => <Link
                to={item.url} key={item.title} state={item}
                className={`${item.url === current ? styles.current : ''}`}
              >
                <div>{item.title}</div>
                <small>{item.subTitle}</small>
              </Link>)}
            </div>
          )
        }}
      </Location>
    )
  }
}

Menu.propTypes = {}

Menu.defaultProps = {}

export default Menu
