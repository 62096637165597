import PropTypes from 'prop-types'
import React from 'react'

import styles from './images.module.scss'

const Images = ({data, column, autoColumn, margin, justifyContent, mask}) => {
  const style = {justifyContent}
  const itemStyle = {}
  let imgBoxStyle = `${styles.imgBox}`

  if (margin.toString() !== '0') {
    itemStyle.marginRight = margin
    style.marginRight = `-${margin}` + (/^\d+$/.test(margin) ? 'px' : '')
  }

  if (mask) {
    imgBoxStyle += ` ${styles.mask}`
  }
  return <div
    className={`
    ${styles.images} ${styles['column' + column]} ${autoColumn
      ? styles.autoColumn
      : ''}
    `}
    style={style}>
    {data.map((item, index) => <div
      className={styles.item}
      key={index}
    >
      {item instanceof Array &&
      item.map((item, index) => <div
        key={index} className={imgBoxStyle} style={itemStyle}>
        <img src={item.src} alt={item.text} />
        {item.text && <div className={styles.text}>{item.text}</div>}
      </div>)}

      {!(item instanceof Array) &&
      <div className={imgBoxStyle} style={itemStyle}>
        <img src={item.src} alt={item.text} />
        {item.text && <div className={styles.text}>{item.text}</div>}
      </div>}
    </div>)}
  </div>
}

Images.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      text: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string.isRequired,
      text: PropTypes.string,
    })),
  ])).isRequired,
  column: PropTypes.number,
  margin: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  justifyContent: PropTypes.string,
  autoColumn: PropTypes.bool,
  mask: PropTypes.bool,
}

Images.defaultProps = {
  margin: '0',
  data: [],
  column: 2,
  justifyContent: 'flex-start',
  autoColumn: true,
  mask: false,
}

export default Images
