export const getMenuList = (page = '') => {
  const list = [
    {title: '微意首页', url: '/', subTitle: 'HOME'},
    {title: 'WEB应用', url: '/case/#web', subTitle: 'WEP APP'},
    {title: '电商服务', url: '/case/#eCommerce', subTitle: 'E-COMMERCE'},
    {title: '微信开发', url: '/case/#weChat', subTitle: 'WeChat'},
    {title: 'APP应用', url: '/case/#app', subTitle: 'APP'},
    {title: '客户案例', url: '/case', subTitle: 'CASE'},
    // {title: '服务流程', url: '/service', subTitle: 'SERVICE'},
    {title: '联系我们', url: '/contactUs', subTitle: 'CONTACT US'},
  ]
  const regexp = new RegExp(`/${page}/.+`)
  return page ? list.filter(menu => {
    return regexp.test(menu.url)
  }) : list
}
