import PropTypes from 'prop-types'
import React from 'react'

import styles from './articles.module.scss'

const ArticlesList = ({data, column, margin}) => {
  const style = {}
  const itemStyle = {}

  if (margin.toString() !== '0') {
    itemStyle.marginRight = margin
    style.marginRight = `-${margin}` + (/^\d+$/.test(margin) ? 'px' : '')
  }

  return <div
    className={`${styles.list} ${styles['column' + column]}`} style={style}>
    {data.map((item, index) => <a
      href={item.href} key={index} className={styles.item}>
      <div style={itemStyle}>
        {item.image &&
        <img src={item.image} alt={item.title} className={styles.image} />}
        <div className={styles.content}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.desc}>{item.desc}</div>
        </div>
      </div>
    </a>)}
  </div>
}

ArticlesList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
      desc: PropTypes.string,
      href: PropTypes.string,
    }),
  ).isRequired,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  column: PropTypes.number,
  margin: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}

ArticlesList.defaultProps = {
  column: 3,
  margin: '5vw',
}

export default ArticlesList
