import React from 'react'

import Layout from '../components/layout'

// import styles from './index.module.scss'
import Banner from '../components/banner'
import Step from '../components/step'
import Title from '../components/title'
import Tabs from '../components/tabs/index'
import TabPane from '../components/tabs/tab-pane'
import Images from '../components/images'
import More from '../components/more'
import ArticlesList from '../components/list/articles'
import ContactUs from '../components/contactus'

const IndexPage = () => (
  <Layout>
    <Banner image={'/images/banner.jpg'} />
    <Step />
    <Title desc={`PROJECT CASES`}>项目案例</Title>
    <div className="container">
      <Tabs defaultActiveKey='1' margin={'0.72rem'}>
        <TabPane tab="WEB" key="1">
          <Images
            data={[
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
            ]}
            column={3}
            margin={'5%'}
            autoColumn
          />
        </TabPane>
        <TabPane tab="APP/UI" key="2">
          <Images
            data={[
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
            ]}
            column={3}
            margin={'5%'}
          />
        </TabPane>
        <TabPane tab="小程序" key="3">
          <Images
            data={[
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
            ]}
            column={3}
            margin={'5%'}
          />
        </TabPane>
        <TabPane tab="电商服务" key="4">
          <Images
            data={[
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
            ]}
            column={3}
            margin={'5%'}
          />
        </TabPane>
        <TabPane tab="产品拍摄" key="5">
          <Images
            data={[
              {
                'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
                text: '123123',
              },
            ]}
            column={3}
            margin={'5%'}
          />
        </TabPane>
      </Tabs>
    </div>
    <Title desc={`WHO CHOOSES US`}>谁选择我们</Title>
    <div>
      <Images
        mask
        data={[
          {
            'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
            text: '大表哥',
          },
          {
            'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
            text: '二表哥',
          },
          {
            'src': 'http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg',
            text: '三表哥',
          },
        ]}
        column={3}
      />
    </div>
    <div className={`container-full`} style={{backgroundColor: '#f5f5f5'}}>
      <div className={`container`}>
        <Title desc={`DYNAMIC NEWS`}>动态要闻</Title>
        <ArticlesList
          data={[
            {
              image: 'https://news-bos.cdn.bcebos.com/mvideo/48df6b1b69a36d963aefb3b8f.jpg',
              title: '数据云上跑 市民省心又省事？',
              desc: '2019年7月26日',
              href: '#',
            }, {
              image: 'https://news-bos.cdn.bcebos.com/mvideo/6275b0d4a6aa8de1cdd2d77b1.jpg',
              title: '河南数字经济“加速度”：今年郑州市主城区实现5g网络全覆盖',
              desc: '2019年7月26日',
              href: '#',
            },
            {
              image: 'https://news-bos.cdn.bcebos.com/mvideo/e0a6b8917c7b073164fa14bf3.jpg',
              title: '抢5g先机？美半导体巨头走出这一步…',
              desc: '2019年7月26日',
              href: '#',
            }, {
              image: 'https://news-bos.cdn.bcebos.com/mvideo/48df6b1b69a36d963aefb3b8f.jpg',
              title: '数据云上跑 市民省心又省事？',
              desc: '2019年7月26日',
              href: '#',
            }, {
              image: 'https://news-bos.cdn.bcebos.com/mvideo/6275b0d4a6aa8de1cdd2d77b1.jpg',
              title: '河南数字经济“加速度”：今年郑州市主城区实现5g网络全覆盖',
              desc: '2019年7月26日',
              href: '#',
            },
            {
              image: 'https://news-bos.cdn.bcebos.com/mvideo/e0a6b8917c7b073164fa14bf3.jpg',
              title: '抢5g先机？美半导体巨头走出这一步…',
              desc: '2019年7月26日',
              href: '#',
            },
          ]}
        />
      </div>
      <More />
    </div>
    <Title desc={`CONTACT US`}>联系我们</Title>
    <div className="container">
      <ContactUs />
    </div>
  </Layout>
)

export default IndexPage
