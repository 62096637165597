import PropTypes from 'prop-types'
import React from 'react'

import styles from './banner.module.scss'

const Banner = ({image, alt}) => (
  <div className={`${styles.banner} container-fluid`}>
    <img src={image} alt={alt} />
  </div>
)

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

Banner.defaultProps = {
  alt: ``,
}

export default Banner
