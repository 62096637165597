import PropTypes from 'prop-types'
import React from 'react'

import styles from './title.module.scss'

const Title = ({children, desc}) => {
  return <div className={`container ${styles.title}`}>
    <div className={styles.text}>{children}</div>
    {desc && <div className={styles.desc}>{desc}</div>}
  </div>
}

Title.propTypes = {
  desc: PropTypes.string,
}

Title.defaultProps = {
  desc: ''
}

export default Title
