import PropTypes from 'prop-types'
import React from 'react'

import styles from './more.module.scss'

const More = ({text}) => {
  return <div className={`container-fluid ${styles.content}`}>
    <div className={styles.text}>{text}</div>
  </div>
}

More.propTypes = {
  text: PropTypes.string,
}

More.defaultProps = {
  text: 'VIEW MORE',
}

export default More
