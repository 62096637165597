// import PropTypes from 'prop-types'
import React from 'react'

import styles from './contactus.module.scss'

const ContactUs = () => (
  <form className={`form ${styles.form}`} method='post'>
    <div className={`item ${styles.item}`}>
      <input type="text" placeholder={'公司名称'} name='company' />
      <input type="text" placeholder={'联系电话'} name='tel' />
      <input type="text" placeholder={'您的姓名'} name='name' />
    </div>
    <div className={`item ${styles.item}`}>
      <textarea name="comments" rows="5" maxLength={200} placeholder={'留言内容'} />
    </div>
    <div className={`item ${styles.item}`}>
      <button className={'button'}>提 交</button>
    </div>
  </form>
)

ContactUs.propTypes = {}

ContactUs.defaultProps = {}

export default ContactUs
